import React, { createContext, useContext, useState, useEffect } from 'react';

export const F2bAuthContext = createContext();

export const F2bAuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    // Fetch the user from graphql
    const fetchUser = async () => {
      const response = await fetch('https://api.myf2b.com/graphql', {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify({
          query: `
          query {
            me {
              user {
                uid
                id
                first_name
                last_name
                email
              }
            }
          }
          `,
        }),
      });
      const data = await response.json();

      // Validate the response
      if (data.errors) {
        // console.log('ERRORS', data.errors);.
        setUser(null);
        return;
      }
      setUser({
        uid: data.data.me.user.uid,
        firstName: data.data.me.user.first_name,
        lastName: data.data.me.user.last_name,
        email: data.data.me.user.email,
      });
    };
    fetchUser();
  }, []);

  const value = { user, setUser };
  return (
    <F2bAuthContext.Provider value={value}>{children}</F2bAuthContext.Provider>
  );
};

export const useF2bAuth = () => {
  const context = useContext(F2bAuthContext);
  if (!context) {
    throw new Error('useF2bAuth must be used within a F2bAuthProvider');
  }
  return context;
};

import React, { createContext, useContext, useEffect, useState } from 'react';
import { AuthContext as OAuth2Context } from '@mlilius/react-oauth2-code-pkce';
import { F2bAuthContext } from './f2bAuthProvider';

export const UnifiedAuthContext = createContext();

export const UnifiedAuthProvider = ({ children }) => {
  const oauth2 = useContext(OAuth2Context);
  const f2bAuth = useContext(F2bAuthContext);

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userProfile, setUserProfile] = useState(null);

  useEffect(() => {
    const authStatus = !!oauth2.tokenData || !!f2bAuth.user;
    setIsAuthenticated(authStatus);

    // Build the user profile
    if (!authStatus) {
      setUserProfile(null);
    } else {
      let profile;
      if (oauth2.tokenData) {
        profile = {
          via: 'oauth2',
          sub: oauth2.tokenData.sub,
          authenticationType: oauth2.tokenData.authenticationType,
          firstName: oauth2.tokenData.firstName,
          lastName: oauth2.tokenData.lastName,
          name: `${oauth2.tokenData.firstName} ${oauth2.tokenData.lastName}`,
          email: oauth2.tokenData.email,
          emailVerified: oauth2.tokenData.email_verified,
          f2bId: null,
          raw: oauth2.tokenData,
        };
      } else if (f2bAuth.user) {
        profile = {
          via: 'f2bSSO',
          sub: f2bAuth.user.uid,
          authenticationType: 'F2B',
          firstName: f2bAuth.user.firstName,
          lastName: f2bAuth.user.lastName,
          name: `${f2bAuth.user.firstName} ${f2bAuth.user.lastName}`,
          email: f2bAuth.user.email,
          emailVerified: null,
          f2bId: f2bAuth.user.id,
          raw: f2bAuth.user,
        };
      }

      setUserProfile(profile);
    }
  }, [oauth2, f2bAuth]);

  const login = oauth2.login;
  const logOut = oauth2.logOut;
  const error = oauth2.error;

  const logOutF2b = () => {
    // Redirect to the logout page
    window.location.href = 'https://myf2b.com/logout';
  };

  return (
    <UnifiedAuthContext.Provider
      value={{ isAuthenticated, userProfile, login, logOut, error, logOutF2b }}
    >
      {children}
    </UnifiedAuthContext.Provider>
  );
};

export const useUnifiedAuth = () => {
  const context = useContext(UnifiedAuthContext);

  if (context === undefined) {
    return {
      isAuthenticated: false,
      userProfile: null,
      login: () => {},
      logOut: () => {},
      error: null,
    };
  }

  return context;
};

import React from 'react';

import { useUnifiedAuth } from '../auth/unifiedAuthProvider';

const LoginFooter = () => {

  const environment = (process.env.NODE_ENV)
  const { isAuthenticated, userProfile, login, logOut, logOutF2b } =
    useUnifiedAuth();

  const fullName = userProfile
    ? `${userProfile.firstName} ${userProfile.lastName}`
    : null;

  return (
    environment === 'development' ? <div style={{ position: 'fixed', bottom: 0, right: 0 }}>
      {isAuthenticated ? (
        <div>
          {fullName} {userProfile.email ? `(${userProfile.email})` : null}
          <button onClick={userProfile.via === 'f2b' ? logOutF2b : logOut}>
            Logout
          </button>
        </div>
      ) : (
        <button onClick={login}>Login</button>
      )}
    </div>: null
  );
};

export default LoginFooter;

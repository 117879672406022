import { navigate } from 'gatsby';

const clientId = '79764b0f-00de-4064-af52-fe795e4b6cca';
const authServer = 'https://myf2b.fusionauth.io';

const config = {
  clientId,
  authorizationEndpoint: `${authServer}/oauth2/authorize`,
  logoutEndpoint: `${authServer}/oauth2/logout?client_id=${clientId}`,
  tokenEndpoint: `${authServer}/oauth2/token`,
  redirectUri: `${window.location.origin}/oauth/callback`,
  scope: 'openid email profile',
  decodeToken: true,
  autoLogin: false,
  logoutRedirect: `${window.location.origin}/`,
  postLogin: () => {
    navigate(localStorage.getItem('postLoginPath') || '/');
  },
};

export default config;

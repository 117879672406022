exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-learn-with-me-index-js": () => import("./../../../src/pages/learn-with-me/index.js" /* webpackChunkName: "component---src-pages-learn-with-me-index-js" */),
  "component---src-pages-learn-with-me-more-games-js": () => import("./../../../src/pages/learn-with-me/more-games.js" /* webpackChunkName: "component---src-pages-learn-with-me-more-games-js" */),
  "component---src-pages-oauth-callback-js": () => import("./../../../src/pages/oauth/callback.js" /* webpackChunkName: "component---src-pages-oauth-callback-js" */),
  "component---src-templates-learn-with-me-js": () => import("./../../../src/templates/learnWithMe.js" /* webpackChunkName: "component---src-templates-learn-with-me-js" */),
  "component---src-templates-lwm-js": () => import("./../../../src/templates/lwm.js" /* webpackChunkName: "component---src-templates-lwm-js" */)
}


import React, { useEffect } from 'react';
import { useUnifiedAuth } from './unifiedAuthProvider';

const TrackingProvider = ({ children }) => {
  const { userProfile } = useUnifiedAuth();

  useEffect(() => {
    if (
      userProfile &&
      typeof window !== 'undefined' &&
      window.rudderanalytics
    ) {
      window.rudderanalytics.identify(userProfile.sub, {
        via: userProfile.via,
        authenticationType: userProfile.authenticationType,
        name: userProfile.name,
        email: userProfile.email,
        emailVerified: userProfile.emailVerified,
        firstName: userProfile.firstName,
        lastName: userProfile.lastName,
        f2bId: userProfile.f2bId,
      });
    }
  }, [userProfile]);

  return <>{children}</>;
};

export default TrackingProvider;

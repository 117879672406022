module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-rudderstack/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"2UlHpOPtf9ChsQW6FdcwcQnNgHm","trackPage":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"tv-myf2b-com","short_name":"tv-myf2b","start_url":"/","background_color":"#663399","display":"minimal-ui","icon":"static/images/f2b-logo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"871878959c09f9ca7fdef8b3c7200f20"},
    },{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"enableListener":true,"preconnect":["https://fonts.googleapis.com","https://fonts.gstatic.com"],"web":[{"name":"Lexend","file":"https://fonts.googleapis.com/css2?family=Lexend:wght@100;200;300;500;600&display=swap"},{"name":"Londrina","file":"https://fonts.googleapis.com/css2?family=Londrina+Solid:wght@300;400&display=swap"}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]

import React from 'react';
import { AuthProvider } from '@mlilius/react-oauth2-code-pkce';
import { F2bAuthProvider } from './src/auth/f2bAuthProvider';
import { UnifiedAuthProvider } from './src/auth/unifiedAuthProvider';
import TrackingProvider from './src/auth/trackingProvider';
import fusionAuthConfig from './src/auth/fusionAuthConfig';

import LoginFooter from './src/components/loginFooter';

export const wrapRootElement = ({ element }) => {
  return (
    <>
      <AuthProvider authConfig={fusionAuthConfig}>
        <F2bAuthProvider>
          <UnifiedAuthProvider>
            <TrackingProvider>
              {element}
              <LoginFooter />
            </TrackingProvider>
          </UnifiedAuthProvider>
        </F2bAuthProvider>
      </AuthProvider>
    </>
  );
};
